<div id="theme_id" class="">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <div class="modal" *ngIf="!hideModal" [@fadeInOut]>
        <div class="cookies-container d-flex align-items-center justify-content-around p-4 gap-3">
            <div class="w-75">
                <h4>¡No te olvides de las Cookies!</h4>
                <p class="m-0">usamos cookies propias y de terceros que nos permiten medir el volumen y la interacción
                    de los usuarios en la web y nos ayudan a mejorarla, permitiéndonos analizar tus hábitos y elaborar
                    perfiles de navegación, respetando en todo caso tu privacidad. Puedes configurar las cookies,
                    rechazar o aceptarlas todas.</p>
            </div>
            <div class="d-flex flex-column gap-2">
                <button class="btn btn-primary btn-rounded waves-effect waves-light" (click)="toggleModal()">Aceptar
                    todas cookies</button>
                <button class="btn btn-primary btn-rounded waves-effect waves-light" (click)="toggleModal()">Solo las
                    necesarias</button>
                <button class="btn btn-primary btn-rounded waves-effect waves-light" (click)="toggleModal()">Rechazar
                    las cookies</button>
            </div>
        </div>
    </div>
</div>