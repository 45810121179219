import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseContactsService {

  constructor(private firestore: Firestore) {
  }

  async sendProductRequest(formData) {
    return await addDoc(collection(this.firestore, 'request'), formData);
  }

  async sendContact(formData) {
    return await addDoc(collection(this.firestore, 'contacts'), formData);
  }

  async newsletterSubscribe(formData) {
    return await addDoc(collection(this.firestore, 'newsletter'), formData);
  }
}
