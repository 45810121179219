import { Component, Injectable, AfterViewInit, HostListener, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Injectable()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

// header component

export class HeaderComponent implements AfterViewInit {
  sections = {};
  curentsection: any;

  languages = [
    { value: 'es', description: 'Español' },
    { value: 'en', description: 'English' },
    { value: 'it', description: 'Italiano' },
  ]

  public loginForm = this.fb.group({
    email: ['', [Validators.email, Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    password: new FormControl('', Validators.required)
  })
  modalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    window.onscroll = function () {
      myFunction()
    };
    var navbar1 = document.getElementById("navbar1");
    function myFunction() {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        navbar1.style.backgroundColor = "#272a33";
        navbar1.style.padding = "10px";
      }
      else {
        navbar1.style.backgroundColor = "";
        navbar1.style.padding = "20px";
      }
    }

    this.curentsection = 'home'
  }

  languageChange(language: string) {
    this.translate.use(language);
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered'
    });
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
    document.getElementById('menu_button').classList.toggle('collapsed');
  }

  ngAfterViewInit() {
  }

  onSectionChange(section: any) {
    this.curentsection = section
  }

  login() {
    if (this.loginForm.controls.email.errors?.required || this.loginForm.controls.password.errors?.required)
      return this.toast.error('Para ingresar debe completar email y contraseña');
    if (this.loginForm.controls.email.errors?.email)
      return this.toast.error('Hay un error en el formato del email que estas intentando ingresar');
    if (this.loginForm.controls.email.errors?.pattern)
      return this.toast.error('Hay un error en el formato del email que estas intentando ingresar');
    this.toast.error('El email y/o contraseña es incorrecto')
  }
}



