import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ScrollspyDirective } from '../scrollspy.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LayoutComponent, ScrollspyDirective],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class LayoutModule { }
