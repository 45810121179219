<div id="theme_id" class="theme-red">

    <!-- light-dark mode -->

    <a href="javascript: void(0);" id="mode" class="mode-btn text-white" (click)="onChangeMode()">
        <i class="mdi mdi-weather-sunny bx-spin mode-light"></i>
        <i class="mdi mdi-moon-waning-crescent mode-dark"></i>
    </a>
    <div class="account-home-btn d-none d-sm-block">
        <a routerLink="/" class="text-white"><i class="mdi mdi-home h1"></i></a>
    </div>

    <section class="vh-100">
        <div class="display-table">
            <div class="display-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5">
                            <div class="card account-card">
                                <div class="card-body">
                                    <div class="text-center mt-3">
                                        <h3 class="font-weight-bold"><a routerLink="/" class="text-dark text-uppercase account-pages-logo"><i class="mdi mdi-alien"></i>Valeare</a></h3>
                                        <p class="text-muted">Sign up for a new Account</p>
                                    </div>
                                    <div class="p-4">
                                        <form>
                                            <div class="form-group">
                                                <label for="firstname">First Name</label>
                                                <input type="text" class="form-control" id="firstname" placeholder="First Name">
                                            </div>

                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="password" class="form-control" id="email" placeholder="Enter Email">
                                            </div>

                                            <div class="form-group">
                                                <label for="userpassword">Password</label>
                                                <input type="password" class="form-control" id="userpassword" placeholder="Enter password">
                                            </div>

                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customControlInline">
                                                <label class="form-check-label" for="customControlInline">Remember me</label>
                                            </div>

                                            <div class="d-grid mt-3">
                                                <button type="submit" class="btn btn-primary">Sign in</button>
                                            </div>

                                            <div class="mt-4 mb-0 text-center">
                                                <p class="mb-0">Don't have an account ? <a routerLink="/login" class="text-danger">Sign in</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>