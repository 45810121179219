import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})

// layout component
export class LayoutComponent implements OnInit {
  public hideModal: boolean = false;

  constructor(private router: Router,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    let theme = localStorage.getItem("theme");
    localStorage.setItem("theme", theme);
    document.body.classList.remove('bg-account-pages');
    document.body.classList.remove('py-4');
    document.body.classList.remove('py-sm-0');

    document.getElementById('theme_id').classList.remove('theme-cyan');
    if (this.router.url == '/') {
      document.getElementById('theme_id').classList.add('theme-cyan');
    }
    if (this.router.url == '/index1') {
      document.getElementById('theme_id').classList.add('theme-cyan');
    }
    else if (this.router.url == '/index2') {
      document.getElementById('theme_id').classList.add('theme-red');
    }
    else if (this.router.url == '/index3') {
      document.getElementById('theme_id').classList.add('theme-green');
    }
    else if (this.router.url == '/index4') {
      document.getElementById('theme_id').classList.add('theme-pink');
    }
    else if (this.router.url == '/index5') {
      document.getElementById('theme_id').classList.add('theme-blue');
    }
    else if (this.router.url == '/index6') {
      document.getElementById('theme_id').classList.add('theme-purple');
    }
    else if (this.router.url == '/index7') {
      document.getElementById('theme_id').classList.add('theme-cyan');
    }
    else if (this.router.url == '/index8') {
      document.getElementById('theme_id').classList.add('theme-orange');
    }
    else if (this.router.url == '/index9') {
      document.getElementById('theme_id').classList.add('theme-yellow');
    }

    this.disableScroll();
    if (
      this.cookieService.get('consent') &&
      JSON.parse(this.cookieService.get('consent'))
    ) {
      this.hideModal = true;
      this.enableScroll();
    }
  }

  toggleModal(): void {
    this.hideModal = true;
    this.cookieService.set('consent', 'true');
    this.enableScroll();
  }

  disableScroll() {
    document.body.classList.add("stop-scrolling");
  }

  enableScroll() {
    document.body.classList.remove("stop-scrolling");
  }
}
