import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Valeare | SaaS Software Solutions';
  constructor(private translateService: TranslateService) {
    const lang = navigator.language.split('-')[0] || 'es';
    this.translateService.use(lang);
  }
}
